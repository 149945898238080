import React from "react"
import { slide as Menu } from 'react-burger-menu'

class Nav extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }

  render () {
    return (
      <Menu disableAutoFocus right>
        <a id="home-nav" className="menu-item" href="/#header">Home</a>
        <a id="about-nav" className="menu-item" href="/#about">About</a>
        <a id="services-nav" className="menu-item" href="/#services">Services</a>
        <a id="projects-nav" className="menu-item" href="/#projects">Projects</a>
        <a id="contact-nav" className="menu-item" href="/#contact">Contact</a>
        <a id="social-nav" className="menu-item" href="/#social">Social</a>
      </Menu>
    );
  }
}

export default Nav;