import React from 'react'
import { Link } from "gatsby";
import Menu from '../components/menu';
//import headerImage from '../images/header-mountain.jpg'

class Header extends React.Component {
    render() {
        return (

            <section id="header" className="header">

            <Menu />

            <div className="text-center">
            <div className="inner">
                <ul className="actions">
                    <li><Link to="/#header" className="button scrolly">Home</Link></li>
                    <li><Link to="/#services" className="button scrolly">Services</Link></li>
                    <li><Link to="/#projects" className="button scrolly">Projects</Link></li>
                    <li><Link to="/#about" className="button scrolly">About</Link></li>
                    <li><Link to="/#contact" className="button scrolly">Contact</Link></li>
                    <li><Link to="/#social" className="button scrolly">social</Link></li>
                </ul>
            </div>
            {/* <img src={headerImage} alt="" /> */}
            <h1 className="header-title">Hoople Web Design</h1>
            <h2 className="text-lg">Here to help you and your business</h2>
            </div>

            </section>
        )
    }
}

export default Header
