import React from 'react'

import TwitterLogo from '../images/Twitter_Social_Icon_Circle_Color.svg'
import SkypeLogo from '../images/skype-icon.svg'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer" className="footer">
                <div className="text-center panel">
                    <div className="panel-content">
                        <ul className="icons">
                            <li><a href="https://twitter.com/HoopleWebDesign"><span className="label"><img src={TwitterLogo} alt="Twitter" class="social-icon" /> @HoopleWebDesign</span></a></li>
                            <li><a href="skype:matt.hallsworth.HWDL?chat"><span className="label"><img src={SkypeLogo} alt="Skype" class="social-icon" /> matt.hallsworth.HWDL</span></a></li>
                        </ul>
                        <ul className="copyright">
                            <li>&copy; Hoople Web Design Limited. Company registration number 09832587</li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default Footer
